
.firstColumn{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.firstColumn .cell{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
